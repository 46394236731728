// src/pages/Home.js
import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/Home.css'; // Add some styles

const Home = () => {
  return (
    <div className="home">
      <h1>Welcome to Stock-Web</h1>
      <p>Your go-to platform for learning option trading!</p>
      <div className="home-links">
        <Link to="/blogs" className="home-link">Read Blogs</Link>
        <Link to="/courses" className="home-link">Explore Courses</Link>
      </div>
    </div>
  );
};

export default Home;
