// src/pages/About.js
import React from 'react';
import '../styles/About.css';

const About = () => {
  return (
    <div className="about-page">
      <h1>About Stock-Web</h1>
      <p>
        At Stock-Web, we are dedicated to helping traders of all levels master the art of options trading.
        Our platform offers a variety of educational resources, tools, and insights designed to empower you to
        make informed trading decisions.
      </p>
      
      <h2>Our Mission</h2>
      <p>
        Our mission is to provide top-notch educational content that demystifies the complexities of trading.
        We aim to foster a community of knowledgeable traders who can navigate the market with confidence.
      </p>
      
      <h2>What We Offer</h2>
      <ul>
        <li><strong>Comprehensive Courses:</strong> Explore our extensive range of courses covering everything from the basics to advanced trading strategies.</li>
        <li><strong>Expert Blogs:</strong> Stay updated with insights from industry experts through our regularly updated blog section.</li>
        <li><strong>Community Support:</strong> Join our community forum to connect with fellow traders, share experiences, and seek advice.</li>
        <li><strong>Resources & Tools:</strong> Access trading calculators, charts, and other tools to assist you in your trading journey.</li>
      </ul>
      
      <h2>Why Choose Stock-Web?</h2>
      <p>
        With years of experience in the trading industry, our team is passionate about sharing knowledge and
        helping others succeed. Whether you are just starting or looking to enhance your skills, Stock-Web
        has something for everyone.
      </p>
      
      <h2>Get Involved</h2>
      <p>
        Join us today and take the first step towards mastering options trading. Sign up for our courses, 
        read our blogs, and become a part of the Stock-Web community!
      </p>
    </div>
  );
};

export default About;
