// src/pages/Blog.js

import React from 'react';
import BlogList from '../components/BlogList';
import '../styles/Blog.css';

// Dummy blog posts
const blogPosts = [
  {
    id: 1,
    title: "Understanding Option Trading Basics",
    content: "Option trading can be complex, but it starts with understanding the basics. This blog will help you grasp the fundamentals of options.",
    author: "John Doe",
    date: "September 20, 2024",
  },
  {
    id: 2,
    title: "Strategies for Successful Option Trading",
    content: "In this post, we will explore various strategies that can enhance your option trading success. From straddles to spreads, learn which strategy is right for you.",
    author: "Jane Smith",
    date: "September 21, 2024",
  },
  {
    id: 3,
    title: "Common Mistakes in Option Trading",
    content: "Avoid these common pitfalls in option trading to improve your chances of success. Learn from the mistakes of others and enhance your trading skills.",
    author: "Alice Johnson",
    date: "September 22, 2024",
  },
];

const Blog = () => {
  return (
    <div className="blog-page">
      <h1>Option Trading Blogs</h1>
      <BlogList posts={blogPosts} />
    </div>
  );
};

export default Blog;
