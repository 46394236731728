// src/components/BlogList.js

import React from 'react';
import '../styles/BlogList.css'; // Create this file for specific styles

const BlogList = ({ posts }) => {
  return (
    <div className="blog-list">
      {posts.map(post => (
        <div key={post.id} className="blog-post">
          <h2>{post.title}</h2>
          <p className="blog-author">{post.author} - {post.date}</p>
          <p>{post.content}</p>
        </div>
      ))}
    </div>
  );
};

export default BlogList;
