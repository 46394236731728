// src/utils/PrivateRoute.js
import React from 'react';
import { Route, Navigate } from 'react-router-dom';
import { auth } from '../fire/FirebaseConfig'; // Assuming Firebase handles authentication

const PrivateRoute = ({ component: Component, ...rest }) => {
  const isAuthenticated = auth.currentUser !== null;

  return (
    <Route
      {...rest}
      element={isAuthenticated ? <Component /> : <Navigate to="/login" />}
    />
  );
};

export default PrivateRoute;
