// src/components/CourseCard.js
import React from 'react';

const CourseCard = ({ title, description, onPurchase }) => {
  return (
    <div className="course-card">
      <h3>{title}</h3>
      <p>{description}</p>
      <button onClick={onPurchase}>Purchase</button>
    </div>
  );
};

export default CourseCard;
