// src/pages/Profile.js
import React, { useState, useEffect } from 'react';
import { auth } from '../fire/FirebaseConfig';
import '../styles/Profile.css';

const Profile = () => {
  const [user, setUser] = useState(null);
  const [bonusMaterial, setBonusMaterial] = useState(null);

  useEffect(() => {
    // Fetch current user from Firebase Auth
    const currentUser = auth.currentUser;
    setUser(currentUser);

    // Fetch bonus materials for logged-in user
    // You can make an API request here to fetch user's bonus material
    setBonusMaterial("Bonus material for members will be displayed here.");
  }, []);

  if (!user) {
    return <p>Loading...</p>;
  }

  return (
    <div className="profile-page">
      <h1>Profile</h1>
      <p>Welcome, {user.email}</p>
      <h2>Bonus Material</h2>
      <p>{bonusMaterial}</p>
    </div>
  );
};

export default Profile;
