// src/pages/Courses.js
import React, { useState, useEffect } from 'react';
import CourseCard from '../components/CourseCard';
import '../styles/Courses.css';

const Courses = () => {
  const [courses, setCourses] = useState([]);

  // Dummy course data
  const dummyCourses = [
    {
      id: 1,
      title: "Introduction to Options Trading",
      description: "Learn the basics of options trading, strategies, and risk management."
    },
    {
      id: 2,
      title: "Advanced Technical Analysis",
      description: "Dive deep into technical analysis with expert techniques and tools."
    },
    {
      id: 3,
      title: "Fundamentals of Stock Market",
      description: "Understand the stock market fundamentals, types of stocks, and investment strategies."
    },
    {
      id: 4,
      title: "Risk Management Strategies",
      description: "Learn how to manage risks effectively in trading and investment."
    },
    {
      id: 5,
      title: "Trading Psychology",
      description: "Explore the psychological aspects of trading and how to improve your mindset."
    },
    {
      id: 6,
      title: "Creating a Trading Plan",
      description: "Build a comprehensive trading plan tailored to your financial goals."
    },
  ];

  useEffect(() => {
    // Set dummy courses instead of fetching from an API
    setCourses(dummyCourses);
  }, []);

  const handlePurchase = (courseId) => {
    console.log(`Purchase initiated for course: ${courseId}`);
    // Trigger payment component or gateway here
  };

  return (
    <div className="courses-page">
      <h1>Available Courses</h1>
      <div className="courses-grid">
        {courses.map(course => (
          <CourseCard
            key={course.id}
            title={course.title}
            description={course.description}
            onPurchase={() => handlePurchase(course.id)}
          />
        ))}
      </div>
    </div>
  );
};

export default Courses;
