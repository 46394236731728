// src/App.js

import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Home from './pages/Home';
import Blog from './pages/Blog';
import Courses from './pages/Courses';
import About from './pages/About';
import Profile from './pages/Profile';
import Login from './components/Login';
import SignUp from './components/SignUp';
import PrivateRoute from './utils/PrivateRoute';
import './styles/App.css';

const App = () => {
  return (
    <Router>
      <div className="App">
        {/* Navbar */}
        <nav className="navbar">
          <div className="navbar-logo">
            <Link to="/">Stock-Web</Link>
          </div>
          <ul className="navbar-links">
            <li><Link to="/">Home</Link></li>
            <li><Link to="/blogs">Blog</Link></li>
            <li><Link to="/courses">Courses</Link></li>
            <li><Link to="/about">About</Link></li>
            <li><Link to="/profile">Profile</Link></li>
          </ul>
          <div className="navbar-auth">
            <Link className="btn-login" to="/login">Login</Link>
            <Link className="btn-signup" to="/signup">Sign Up</Link>
          </div>
        </nav>

        {/* Routes */}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/blogs" element={<Blog />} />
          <Route path="/courses" element={<Courses />} />
          <Route path="/about" element={<About />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/profile" element={<PrivateRoute component={Profile} />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
